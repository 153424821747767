"use strict";

const body = document.querySelector('body');
const ariaHiddenItems = document.querySelectorAll('[aria-hidden="false"]');
const ariaVisibleItems = document.querySelectorAll('[aria-hidden="true"]');

// Modal

const openSearchModal = document.querySelector('.search-toggle');
const closeSearchModal = document.querySelector('.search-modal__close');

const searchModal = document.querySelector('.search-modal');


openSearchModal.addEventListener('click', function(){
  ariaHiddenItems.forEach(function(el){
    el.setAttribute('aria-hidden', 'true');
  });
  searchModal.hidden=false;
  searchModal.classList.add('is-open');
});

closeSearchModal.addEventListener('click', function(){
  ariaVisibleItems.forEach(function(el){
    el.setAttribute('aria-hidden', 'hidden');
  });
  searchModal.hidden=true;
  searchModal.classList.remove('is-open');
});

// Main navigation

const mainNavToggle = document.querySelector('.main-nav-toggle');
const pgHeader = document.querySelector('.pg-header-outer');

mainNavToggle.addEventListener('click', function(){
  this.classList.toggle('is-active');
  pgHeader.classList.toggle('nav-is-open');
  body.classList.toggle('no-scroll');
})

const parentNavItems = document.querySelectorAll('.main-nav__list .parent');
const subNavs = document.querySelectorAll('.main-nav__list .parent > .sub-menu');

parentNavItems.forEach(function(el){
  const subNavToggle = el.querySelector('a');
  const subNav = el.querySelector('.sub-menu');

  subNavToggle.addEventListener('click', function(e){
    
    if(window.innerWidth > 1024) {
      return false;
    }

    e.target.classList.toggle('is-active');
    subNav.classList.toggle('is-open');
    e.preventDefault();
  })

});

// Knowledge feed slider

// const contentFeedItemWidth = document.querySelector('.content-feed__item').offsetWidth;

// console.log(contentFeedItemWidth);

const contentFeedSliderOverflows = document.querySelectorAll(".overflow");

contentFeedSliderOverflows.forEach(function(el){
  const contentFeedSlider = el.querySelector('.content-feed--slider');
  const scrollLeft = el.querySelector('.content-feed-control--left');
  const scrollRight = el.querySelector('.content-feed-control--right');
  const contentFeedSliderPos = contentFeedSlider.getBoundingClientRect().left;
  const contentFeedSliderOffsetWidth = window.innerWidth - contentFeedSliderPos;

  const contentFeedItemWidth = el.querySelector('.content-feed__item').offsetWidth;

  contentFeedSlider.style.width = contentFeedSliderOffsetWidth + "px";

  if(scrollLeft) {
    
  scrollLeft.addEventListener('click', function () {
    const currentScrollPosition = contentFeedSlider.scrollLeft;
    const newScrollPosition = currentScrollPosition - contentFeedItemWidth;

    contentFeedSlider.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  });

  scrollRight.addEventListener('click', function () {
    const currentScrollPosition = contentFeedSlider.scrollLeft;
    const newScrollPosition = currentScrollPosition + contentFeedItemWidth;

    contentFeedSlider.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  });

  }

});

console.log(contentFeedSliderOverflows);

function resizeListener() {
  contentFeedSliderOverflows.forEach(function(el){
    const contentFeedSlider = el.querySelector('.content-feed--slider');
    let contentFeedSliderPos = contentFeedSlider.getBoundingClientRect().left;
    let contentFeedSliderOffsetWidth = window.innerWidth - contentFeedSliderPos;
    contentFeedSlider.style.width = contentFeedSliderOffsetWidth + "px";
  });
}

window.addEventListener("resize", resizeListener);

// Content carousels

// Product image gallery

const contentCarouselOuter = document.querySelectorAll('.content-carousel');

contentCarouselOuter.forEach(function(contentCarousel){
  const contentCarouselSlides = contentCarousel.querySelector('.content-carousel__slides');
  const contentCarouselPrev = contentCarousel.querySelector('.content-carousel__control--prev');
  const contentCarouselNext = contentCarousel.querySelector('.content-carousel__control--next');

  const contentCarouselSlider = tns({
    container: contentCarouselSlides,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    mouseDrag: true,
    loop: false,
    prevButton: contentCarouselPrev,
    nextButton: contentCarouselNext,
    responsive: {
      640: {
        autoplay: false
      }
    }
  });

})

// Accordions

let openCurrentAccordionBlock = '';
const accordions = document.querySelectorAll('.accordion-block');
const accordionToggles = document.querySelectorAll('.accordion-block__toggle');

accordions.forEach(function (el) {
  // const accordionToggles = el.querySelectorAll('.accordion-block__toggle');
  const currentAccordionToggle = el.querySelector('.accordion-block__toggle');
  const currentAccordionAnswer = el.querySelector('.accordion-block__answer');
  
    currentAccordionToggle.addEventListener('click', function(){
      
      // remove is-active from all here
      accordionToggles.forEach(function (el) {
        el.classList.remove('is-active');
      });
      
      const allAccordionAnswers = document.querySelectorAll('.accordion-block__answer');
      
      allAccordionAnswers.forEach(function(el) {
        el.classList.remove('is-open');
      });
      
      if(openCurrentAccordionBlock != currentAccordionAnswer.id) {
        currentAccordionAnswer.classList.add('is-open');
        // then add is-active to the clicked one
        currentAccordionToggle.classList.add('is-active');
        openCurrentAccordionBlock = currentAccordionAnswer.id;
      } else {
        openCurrentAccordionBlock = '';
      }
    });
    
});

// Filtering and pagination

const contentFeedFixed = document.querySelector('.content-feed--fixed');
const targetSelector = '.mix';

if (contentFeedFixed) {
  const mixer = mixitup(contentFeedFixed, {
    animation: {
      enable: false,
      animateResizeContainer: false
    },
    pagination: {
      limit: 12,
      hidePageListIfSinglePage: true,
      loop: true
    },
    selectors: {
      pageList: '.pagination-outer',
      target: targetSelector
    },
    templates: {
      pagerPrev:
      '<div class="pagination mt-lg"><button data-page="prev" type="button" class="pagination-control pagination-control--prev ${classNames}" aria-label="Go to the previous page"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="m28.57 100-7.14-7.14 42.86-42.86-42.86-42.86 7.14-7.14 50 50z"/></svg></button>',
      pagerNext:
        '<button data-page="next" type="button" class="pagination-control pagination-control--next ${classNames}" aria-label="Go to the next page"><svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="m28.57 100-7.14-7.14 42.86-42.86-42.86-42.86 7.14-7.14 50 50z"/></svg></button></div>',
      pager:
        '<button type="button" class="pagination-pg ${classNames}" data-page="${pageNumber}" aria-label="Navigate to page ${pageNumber}">${pageNumber}</button>'
    }
  });
}

document.addEventListener("DOMContentLoaded", function() {
const pgNumbers = document.querySelectorAll('.pagination .mixitup-control');

pgNumbers.forEach((number)=> {
  number.addEventListener('click', function(){
    window.scrollTo(0,0);
  });
});

});

// $(document).on('click', '.mixitup-control', function() {
//   window.scrollTo(0, 0);
// });



// Text/image column sizing

function addClassBasedOnHeight() {
  const textImgs = document.querySelectorAll('.text-img');

  textImgs.forEach((textImg)=> {
    const textCol = textImg.querySelector('.text-img__text');

    const element1 = textImg.querySelector(".std-content");
    const element2 = textImg.querySelector(".text-img__img");
  
    const height1 = element1.clientHeight;
    const height2 = element2.clientHeight;
  
    textCol.classList.remove("text-img__text--align-start");
  
    if (height1 > height2) {
      textCol.classList.add("text-img__text--align-start");
    }
  });
  
}

addClassBasedOnHeight();
window.addEventListener("resize", addClassBasedOnHeight);

// Content feed classes

const contentFeeds = document.querySelectorAll('.content-feed-outer--red-mid');

// Loop through the elements and add the "is-even" class to even elements
contentFeeds.forEach((element, index) => {
  if (index % 2 === 1) { // Check if the index is even (0-based)
    element.classList.add('is-even');
  }
});



const downloadFormButtons = document.querySelectorAll('.show-download-form');

downloadFormButtons.forEach(function (el) {
  el.addEventListener('click', function(){

    let fileId = el.getAttribute('data-file-id');

    document.getElementById('download-' + fileId).style.display = 'block';

  });
});

const downloadFormSubmitButtons = document.querySelectorAll('input[name="download_file"]');

downloadFormSubmitButtons.forEach(function (el) {
  el.addEventListener('click', function(){

    let fileId = el.getAttribute('data-file-id');
    let errors = false;
    let fn = document.querySelector('#download-' + fileId + ' input[name="first_name"]');
    let ln = document.querySelector('#download-' + fileId + ' input[name="last_name"]');
    let email = document.querySelector('#download-' + fileId + ' input[name="email"]');

    if(!fn.value) {
      document.querySelector('#download-' + fileId + ' .first-name-error').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('#download-' + fileId + ' .first-name-error').style.display = 'none';
      errors = false;
    }

    if(!ln.value) {
      document.querySelector('#download-' + fileId + ' .last-name-error').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('#download-' + fileId + ' .last-name-error').style.display = 'none';
      errors = false;
    }

    if(!email.value) {
      document.querySelector('#download-' + fileId + ' .email-error').style.display = 'block';
      errors = true;
    } else {
      document.querySelector('#download-' + fileId + ' .email-error').style.display = 'none';
      errors = false;
    }
    
    if(!errors) {
      document.querySelector('#download-' + fileId + ' .success').style.display = 'block';
      window.location.href = '/?file-download=' + fileId;
    } else {
      document.querySelector('#download-' + fileId + ' .success').style.display = 'none';
    }

  });
});